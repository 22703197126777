<template>
  <div>
    <v-row>
      <v-col cols="12" md="7" v-if="$vuetify.breakpoint.mdAndUp">
        <div class="transparent" style="height: 90vh">
          <div style="padding-top: 20vh" class="rounded-circle">
            <img
              class="rounded-circle"
              :src="require('../assets/login.jpeg')"
              alt=""
            />
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="5">
        <div style="height: 80vh" class="secondary">
          <v-row align="center" justify="center" style="margin-top: 10vh">
            <v-col cols="12" md="8">
              <div class="center-logo">
                <app-logo></app-logo>
              </div>
              <v-card class="pa-4 rounded-xl" flat>
                <v-card-text>
                  <strong>Ready to work? kindly login to continue</strong>
                </v-card-text>
                <v-form class="mt-4 text-left" lazy-validation ref="form">
                  <label><small>Email Address</small></label>
                  <v-text-field
                    rounded
                    dense
                    outlined
                    prepend-inner-icon=""
                    v-model="form.email"
                    placeholder="Enter your email address"
                    type="email"
                    background-color="secondary"
                    :rules="validate.required('email')"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon class="ml-n3 primary--text">
                        mdi-email-outline
                      </v-icon>
                    </template>
                  </v-text-field>
                  <label><small>Password</small></label>
                  <v-text-field
                    rounded
                    dense
                    outlined
                    append-icon=""
                    prepend-inner-icon=""
                    v-model="form.password"
                    placeholder="Enter your password"
                    :type="showPassword ? 'text' : 'password'"
                    background-color="secondary"
                    :rules="validate.required('password')"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon class="ml-n3 primary--text">
                        mdi-lock-outline
                      </v-icon>
                    </template>
                    <template v-slot:append>
                      <v-icon
                        color="primary"
                        @click="showPassword = !showPassword"
                      >
                        {{
                          !showPassword
                            ? "mdi-eye-outline"
                            : "mdi-eye-off-outline"
                        }}
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-form>
                <v-card-actions>
                  <v-btn
                    :loading="loading"
                    large
                    rounded
                    class="px-10 capitalize"
                    color="primary"
                    @click="$refs.form['validate']() ? loginAdmin() : null"
                  >
                    Submit
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AppLogo from "../components/Logo";
import FormValidation from "../utils/FormValidation";
import { mapActions } from "vuex";
export default {
  name: "LoginPage",
  components: {
    AppLogo,
  },
  data() {
    return {
      form: {},
      showPassword: false,
      loading: false,
      validate: new FormValidation(),
    };
  },
  methods: {
    ...mapActions("auth", ["login_admin"]),
    async loginAdmin() {
      this.loading = true;
      try {
        await this.login_admin(this.form);
        this.loading = false;
        const url = this.$route.query.redirect
          ? this.$route.query.redirect
          : "/dashboard";
        await this.$router.replace(url);
      } catch (e) {
        this.loading = false;
        this.$response.sendError(e);
      }
    },
  },
};
</script>
